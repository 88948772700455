import * as React from 'react';
import { Box, Heading, Stack, Text, VStack } from '@chakra-ui/react';

import Layout from '../../layout';
import Head from '../../components/head';

const TermsAndConditions: React.FC = () => (
  <Layout headerColorScheme="light">
    <Head title="Terms and Conditions - Jamestown" />

    <Box
      as="section"
      position="relative"
      padding="3rem calc(var(--cp) + 2rem) 5rem"
      sx={{ margin: '0 auto !important' }}
    >
      <VStack
        w="600px"
        maxW="100%"
        spacing={5}
        p={10}
        sx={{ margin: '0 auto !important' }}
      >
        <Heading size="2xl" color="purple">
          Terms and Conditions
        </Heading>
        <Text fontWeight={500} textAlign="center">
          By accessing and placing an order with Jamestown, you confirm that you
          are in agreement with and bound by the terms and conditions
        </Text>
      </VStack>

      <Stack spacing={8}>
        <Text>
          We are one of the fastest emerging E-commerce platforms in the
          Nigeria, we boast of a robust customer base and aid our customers with
          their everyday digital transaction as well as the sales of
          sophisticated phones, laptops and other similar accessories via our
          website and WhatsApp platform.{' '}
        </Text>

        <Text>
          We have quickly peaked to being one the most reliable platforms for
          our customer&apos;s day to day deals especially with our 24/7 policy
          that allows for non- stop transaction all hours of the day. Here at
          JamestownNg, our principles are built on convenience, integrity,
          modernity, timeliness and customer satisfaction. Definitely, we are
          your best bet and your go to for anything E- commerce especially if
          stress-free is your watchword. Sit back and relax! Allow us be a part
          of your success story today as we lessen your burden and make smooth
          as well as effortless the handling of your business deals. We have
          quickly peaked to being one the most reliable platforms for our
          customer&apos;s day to day deals especially with our 24/7 policy t
          that allows for non- stop transaction all hours of the day. Here at
          Jamestown, our principles are built on convenience, integrity,
          modernity, timeliness and customer satisfaction. Definitely, we are
          your best bet and your go to for anything E- commerce especially if
          stress-free is your watchword. Sit back and relax! Allow us be a part
          of your success story today as we lessen your burden and make smooth
          as well as effortless the handling of your business deals.
        </Text>

        <Text>
          We have quickly peaked to being one the most reliable platforms for
          our customer&apos;s day to day deals especially with our 24/7 policy
          that allows for non- stop transaction all hours of the day. Here at
          JamestownNg, our principles are built on convenience, integrity,
          modernity, timeliness and customer satisfaction. Definitely, we are
          your best bet and your go to for anything E- commerce especially if
          stress-free is your watchword. Sit back and relax! Allow us be a part
          of your success story today as we lessen your burden and make smooth
          as well as effortless the handling of your business deals.
        </Text>

        <VStack align="left" spacing={5}>
          <Heading size="md">Changes about terms</Heading>
          <Text>
            If we change our terms of use we will post those changes on this
            page. Registered users will be sent an email that outlines changes
            made to the terms of use.
          </Text>
        </VStack>
      </Stack>
    </Box>
  </Layout>
);

export default TermsAndConditions;
